<template>
  <div class="eLearningYoutubeCard">
    <div :alt="`${item.title}`" class="embed-container">
      <iframe :src="youtubeURL" frameborder="0" allowfullscreen></iframe>
    </div>
    <h3 class="eLearningYoutubeCard__title">{{ item.title }}</h3>
  </div>
</template>

<script>
export default {
  props: {
    item: {
      type: Object,
      default: () => ({
        title: '地景保育兩三事-水金九大地的博物館',
        link: 'https://picsum.photos/id/684/388/259'
      })
    }
  },
  data() {
    return {}
  },
  computed: {
    youtubeURL() {
      const originalURL = this.item.link
      const youtubeId = originalURL
        .replace('https://youtu.be/', '')
        .replace('https://www.youtube.com/watch?v=', '')
        .replace('&', '?')
      return `https://www.youtube.com/embed/${youtubeId}`
    }
  }
}
</script>

<style lang="scss">
.eLearningYoutubeCard {
  width: 50%;
  padding: 1.8rem;
  margin-bottom: 2.2rem;

  &__title {
    font-weight: 500;
    font-size: 1.6rem;
    line-height: 2.3rem;
    margin-top: 1.2rem;
    padding-left: 1.6rem;
  }

  .embed-container {
    position: relative;
    padding-bottom: 56.25%;
    height: 0;
    overflow: hidden;
    max-width: 100%;
  }
  .embed-container iframe,
  .embed-container object,
  .embed-container embed {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
}

@media (max-width: 768px) {
  .eLearningYoutubeCard {
    width: 100%;
    margin-bottom: 2.8rem;
  }
}
</style>
